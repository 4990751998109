export function getDate(date: string, locale: string) {
  const newDate = new Date(date);
  const year = new Intl.DateTimeFormat(locale, {
    year: "numeric",
  }).format(newDate);
  const month = new Intl.DateTimeFormat(locale, {
    month: "long",
  }).format(newDate);
  const day = new Intl.DateTimeFormat(locale, {
    day: "2-digit",
  }).format(newDate);
  const weekday = new Intl.DateTimeFormat(locale, {
    weekday: "long",
  }).format(newDate);
  const dateLocale =
    locale === "es-PE"
      ? `${
          weekday.charAt(0).toLocaleUpperCase() + weekday.slice(1)
        }, ${day} de ${month.toLowerCase()} de ${year}`
      : `${
          month.charAt(0).toLocaleUpperCase() + month.slice(1)
        } ${day}, ${year}`;

  return dateLocale;
}
