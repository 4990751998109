/** @jsx jsx */
import { useContext } from "react";
import { Link } from "gatsby";
import { Heading, jsx } from "theme-ui";
import Img from "gatsby-image";

import { BlogPreview } from "../../types/blog";
import { AppContext } from "../../context/app-context";
import { getDate } from "../../utils/date";

interface BlogProps {
  title: string;
  blogs: BlogPreview[];
}

export function BlogPosts({ blogs, title }: BlogProps) {
  const { locale } = useContext(AppContext);
  const basePath = locale === "es-PE" ? "es" : "en";
  return (
    <div sx={{ py: [4, null, null, 5], bg: "footer" }}>
      <Heading as="h2" sx={{ variant: "text.title" }}>
        {title}
      </Heading>
      <div
        sx={{
          display: "grid",
          gridTemplateColumns: [null, "repeat(3,1fr)"],
          px: 3,
          gap: 5,
          maxWidth: ["960px", null, null, null, "1280px"],
          mx: "auto",
        }}
      >
        {blogs.map((blog) => {
          const news =
            blog.tag === "noticias" && locale === "es-PE"
              ? "noticias/ultimas-noticias"
              : "news/latest-news";
          const events =
            blog.tag !== "noticias" && locale === "es-PE"
              ? "noticias/eventos"
              : "news/events";
          const middlePath = blog.tag === "noticias" ? news : events;
          const date = getDate(blog.createdAt, locale);
          return (
            <Link
              key={blog.slug}
              to={`/${basePath}/${middlePath}/${blog.slug}`}
              sx={{
                bg: "white",
                boxShadow:
                  "0px 0px 1px rgba(40,41,61,0.04), 0px 2px 4px rgba(96,97,112,0.16)",
                display: "grid",
                p: 2,
                textDecoration: "none",
                color: "text",
              }}
            >
              <div>
                <Img
                  fluid={{ ...blog.metaImage?.fluid, aspectRatio: 16 / 9 }}
                />
              </div>

              <div
                sx={{
                  p: 2,
                  display: "grid",
                  height: ["62px", null, null, "80px"],
                  overflow: "hidden",
                }}
              >
                <Heading
                  as="h3"
                  sx={{
                    textAlign: "center",
                    p: 2,
                    fontSize: [2, null, null, null, 3],
                    fontWeight: 700,
                    textTransform: "uppercase",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                >
                  {blog.title}
                </Heading>
              </div>
              <span
                sx={{
                  fontSize: 0,
                  textAlign: "right",
                  my: 1,
                }}
              >
                {date}
              </span>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
