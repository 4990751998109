/** @jsx jsx */
import { graphql } from "gatsby";
import { jsx } from "theme-ui";
import { BlogPosts } from "../components/distribution/blog-posts";
import { Hero } from "../components/distribution/hero";
import { Layout } from "../components/layout/main-layout";
import { Seo } from "../components/seo/seo";

export default function BlogTemplate({ data, pageContext }) {
  const lang = pageContext.locale === "es-PE" ? "es" : "en";
  const page = data.contentfulPage;
  const blogs = data.allContentfulBlog.nodes;
  const news = blogs.filter((blog) => blog.tag === "noticias");
  const events = blogs.filter((blog) => blog.tag !== "noticias");
  return (
    <Layout>
      <Seo
        title={page.title}
        lang={lang}
        image={{ src: page.image.fluid.src, height: 300, width: 300 }}
      />
      <Hero image={page.image.fluid} title={page.title} />
      <BlogPosts
        blogs={page.contentful_id === "14jU7JKuRe6W7MPxH4SlbE" ? news : events}
        title={blogs.title}
      />
    </Layout>
  );
}

export const query = graphql`
  query($locale: String, $slug: String) {
    contentfulPage(node_locale: { eq: $locale }, slug: { eq: $slug }) {
      title
      template
      contentful_id
      image {
        fluid(maxWidth: 1920, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      content {
        content
      }
    }
    allContentfulBlog(
      filter: { node_locale: { eq: $locale } }
      sort: { order: DESC, fields: updatedAt }
    ) {
      nodes {
        tag
        createdAt(locale: "es-PE")
        title
        metaImage {
          fluid {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        slug
      }
    }
  }
`;
